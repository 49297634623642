import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UserProvider from "./providers/UserProvider";

import "./App.scss";
import DefaultLayout from "./layout/DefaultLayout";
import Login from "./components/Login";
import { ProtectedRoute } from "./components/ProtectedRoute";

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/" component={DefaultLayout} />
        </Switch>
      </Router>
    </UserProvider>
  );
};

export default App;
