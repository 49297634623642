import React, { useState, createContext, useEffect } from 'react';
import { firestore } from '../firebase';
import { collectIdsAndDocs } from "../utilities";

const CreatorContext = createContext();

const CreatorProvider = props => {
    const [creators, setCreators] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("creators")
            .onSnapshot(async docRefs => {
                const creators = docRefs.docs.map(collectIdsAndDocs);
                setCreators(creators);
            });

        return () => unsubscribe();
    }, []);

    return (
        <CreatorContext.Provider value={ creators }>
            {props.children}
        </CreatorContext.Provider>
    )
}

export { CreatorProvider, CreatorContext }