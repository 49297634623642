import React, { useState, useContext, createContext, useEffect } from "react";
import { firestore } from "../firebase";
import { collectIdsAndDocs } from "../utilities";
import { SeriesContext } from "./SeriesProvider";


const PublishersContext = createContext({
  publishers: [],
  uniquePublishers: []
});

const PublishersProvider = props => {
  const { series } = useContext(SeriesContext);
  const [publishers, setPublishers] = useState([]);
  const [uniquePublishers, setUniquePublishers] = useState([]);

  useEffect( () => {
    const uniquePublishers = [...new Set(series
      .filter(curSeries => {
          return curSeries.publisher && curSeries.publisher !== "";
      })
      .map(curSeries => curSeries.publisher)
    )];

    setUniquePublishers(uniquePublishers);

    const unsubscribe = firestore
      .collection("publishers")
      .onSnapshot(docRefs => {
        const publishers = docRefs.docs.map(collectIdsAndDocs);
          setPublishers(publishers);
      });

    return () => unsubscribe();
  }, [series]);

  return (
    <PublishersContext.Provider value={{ publishers, uniquePublishers }}>
      {props.children}
    </PublishersContext.Provider>
  );
};

export { PublishersProvider, PublishersContext };
