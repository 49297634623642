import React from "react";

const Dashboard = React.lazy(() => import("./components/Dashboard"));
const ViewSeries = React.lazy(() => import("./components/Series/ViewSeries"));
const AddSeriesByRss = React.lazy(() =>
  import("./components/Series/AddSeriesByRss")
);
const ManageSeries = React.lazy(() =>
  import("./components/Series/ManageSeries")
);
const SeriesReview = React.lazy(() =>
  import("./components/Series/SeriesReview")
);
const Tracks = React.lazy(() => import("./components/Tracks/ManageTracks"));
const ViewPodcastFeeds = React.lazy(() =>
  import("./components/Podcasts/ViewPodcastFeeds")
);
const ViewCuratedLists = React.lazy(() =>
  import("./components/CuratedLists/ViewCuratedList")
);
const ViewUsers = React.lazy(() => import("./components/Users/ViewUsers"));
const ViewGifts = React.lazy(() => import("./components/Gifts/ViewGifts"));
const ViewReports = React.lazy(() =>
  import("./components/Reports/ViewReports")
);
const CreateReport = React.lazy(() =>
  import("./components/Reports/CreateReport")
);
const ViewPublishers = React.lazy(() =>
  import("./components/Publishers/ViewPublishers")
);
const AddPublisher = React.lazy(() =>
  import("./components/Publishers/AddPublisher")
);
const ViewReportedTracks = React.lazy(() =>
  import("./components/Tracks/ReportedTracks")
);
const ViewGenres = React.lazy(() => import("./components/Genres/ViewGenres"));
const ManageFAQ = React.lazy(() => import("./components/ManageFAQ/ManageFAQ"));
const ManageTerms = React.lazy(() =>
  import("./components/ManageTermsAndPrivacy/ManageTerms")
);
const ManagePrivacy = React.lazy(() =>
  import("./components/ManageTermsAndPrivacy/ManagePrivacy")
);
const ManageContentRequirements = React.lazy(() => 
  import ("./components/ManageTermsAndPrivacy/ManageContentRequirements")
);
const Cleanup = React.lazy(() => import("./components/Utilities/Cleanup"));
// const ViewAppSliders = React.lazy(() =>
//   import("./components/NativeAppHomeScreen/ViewSliderRows.js")
// );
// const AddSliderRow = React.lazy(() =>
//   import("./components/NativeAppHomeScreen/AddSliderRow.js")
// );
const CreatorPayouts = React.lazy(() => import("./components/Payouts/CreatorPayouts"));

const routes = [
  /* Main */
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },

  /* Series */
  { path: "/series", name: "View Series", component: ViewSeries, exact: true },
  {
    path: "/series/:seriesId",
    name: "Manage Series",
    component: ManageSeries,
    exact: true
  },
  {
    path: "/series/:seriesId/tracks",
    name: "Manage Tracks",
    component: Tracks,
    exact: true
  },
  {
    path: "/add-series-rss",
    name: "Add Series by RSS",
    component: AddSeriesByRss,
    exact: true
  },
  {
    path: "/series-review",
    name: "Series for Review",
    component: SeriesReview,
    exact: true
  },

  /* Podcasts */
  {
    path: "/podcast-feeds",
    name: "Podcast Feeds",
    component: ViewPodcastFeeds
  },

  /* Curated Content */
  {
    path: "/curated-lists",
    name: "Curated Series Lists",
    component: ViewCuratedLists
  },

  /* Users */
  { path: "/users", name: "Users", component: ViewUsers },
  { path: "/gifts", name: "Gifts", component: ViewGifts },

  /* Payouts */
  { path: "/creatorpayouts", name: "Payouts", component: CreatorPayouts },

  /* Reports */
  { path: "/reports", name: "Reports", component: ViewReports },
  { path: "/create-report", name: "Create Report", component: CreateReport },

  /* Publishers */
  {
    path: "/publishers",
    name: "Publishers",
    component: ViewPublishers,
    exact: true
  },
  {
    path: "/publishers/:publisherId",
    name: "Edit Publisher",
    component: AddPublisher,
    exact: true
  },
  { path: "/add-publisher", name: "Add Publisher", component: AddPublisher },

  /* Site Content */
  { path: "/reported-tracks", name: "Reported Tracks", component: ViewReportedTracks },
  { path: "/genres", name: "Genres", component: ViewGenres },
  { path: "/faq", name: "Manage FAQ", component: ManageFAQ },
  { path: "/terms", name: "Manage Terms", component: ManageTerms },
  { path: "/privacy", name: "Manage Privacy Policy", component: ManagePrivacy },
  { path: "/content-requirements", name: "Manage Content Requirements", component: ManageContentRequirements },
  // /* Native App Content */
  // {
  //   path: "/sliders",
  //   name: "Collection Slider",
  //   component: ViewAppSliders
  // },
  // {
  //   path: "/slider-form",
  //   name: "Add Collection Slider",
  //   component: AddSliderRow
  // },

  /* Utilities */
  { path: "/cleanup", name: "Cleanup", component: Cleanup }
];

export default routes;
