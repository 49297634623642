import React, { useState, createContext, useEffect } from 'react';
import { firestore } from '../firebase';
import { collectIdsAndDocs } from "../utilities";

const SeriesReviewContext = createContext({
    reviewSeries: []
});

const SeriesReviewProvider = props => {
    const [reviewSeries, setReviewSeries] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("seriesReview")
            .onSnapshot(async docRefs => {
                const series = docRefs.docs.map(collectIdsAndDocs);
                setReviewSeries(series);
            });

        return () => unsubscribe();
    }, []);

    return (
        <SeriesReviewContext.Provider value={{ reviewSeries }}>
            {props.children}
        </SeriesReviewContext.Provider>
    )
}

export { SeriesReviewProvider, SeriesReviewContext }