export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-home"
    },
    {
      title: true,
      name: "Catalog"
    },
    {
      name: "Series",
      icon: "icon-disc",
      children: [
        {
          name: "View Series",
          url: "/series"
        },
        {
          name: "Add Series",
          url: "/series/new"
        },
        {
          name: "Add Series By RSS",
          url: "/add-series-rss"
        },
        {
          name: "Series for Review",
          url: "/series-review"
        }
      ]
    },
    {
      name: "Podcasts",
      icon: "icon-feed",
      children: [
        {
          name: "View Podcast Feeds",
          url: "/podcast-feeds"
        }
      ]
    },
    {
      name: "Curated Content",
      icon: "icon-list",
      children: [
        {
          name: "View Curated Series",
          url: "/curated-lists"
        }
      ]
    },
    {
      title: true,
      name: "Users"
    },
    {
      name: "Manage Users",
      icon: "icon-people",
      children: [
        {
          name: "View Users",
          url: "/users"
        }
      ]
    },
    {
      name: "Gifts",
      url: "/gifts",
      icon: "icon-present"
    },
    {
      title: true,
      name: "Payouts",
    },
    {
      name: "Creator Payouts",
      url: "/creatorpayouts",
      icon: "icon-credit-card"
    },
    {
      title: true,
      name: "Reporting"
    },
    {
      name: "Manage Reports",
      icon: "icon-notebook",
      children: [
        {
          name: "View Reports",
          url: "/reports"
        },
        {
          name: "Create Report",
          url: "/create-report"
        }
      ]
    },
    {
      name: "Publishers",
      icon: "icon-wallet",
      children: [
        {
          name: "View Publishers",
          url: "/publishers"
        },
        {
          name: "Add Publisher",
          url: "/add-publisher"
        }
      ]
    },
    {
      title: true,
      name: "Site Content"
    },
    {
      name: "Reported Tracks",
      url: "/reported-tracks",
      icon: "icon-exclamation"
    },
    {
      name: "Genres",
      url: "/genres",
      icon: "icon-pie-chart"
    },
    {
      name: "Manage F.A.Q.",
      url: "/faq",
      icon: "icon-info"
    },
    {
      name: "Site Documents",
      icon: "icon-docs",
      children: [
        {
          name: "Terms of Service",
          url: "/terms"
          // icon: "icon-docs"
        },
        {
          name: "Privacy Policy",
          url: "/privacy"
          // icon: "icon-docs"
        },
        {
          name: "Content Requirements",
          url: "/content-requirements"
        }
      ]
    },

    // {
    //   title: true,
    //   name: "Native App Content"
    // },
    // {
    //   name: "Collection Sliders",
    //   icon: "icon-layers",
    //   children: [
    //     {
    //       name: "View Sliders",
    //       url: "/sliders"
    //     },
    //     {
    //       name: "Add Slider",
    //       url: "/slider-form"
    //     }
    //   ]
    // },

    {
      title: true,
      name: "Utilities"
    },
    {
      name: "Cleanup",
      url: "/cleanup",
      icon: "icon-wrench"
    }
  ]
};
