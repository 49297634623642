import React, { createContext, useEffect, useState } from "react";
import { firestore } from "../firebase";
import { collectIdsAndDocs } from "../utilities";

const UsersContext = createContext({
  users: []
});

const UsersProvider = props => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      firestore
        .collection("users")
        .orderBy("createdAt", "desc")
        .onSnapshot((userRefs) => {
          setUsers(userRefs.docs.map(collectIdsAndDocs))
        });
    }

    fetchData();

  }, []);

  return (
    <UsersContext.Provider value={users}>
      {props.children}
    </UsersContext.Provider>
  );
}

export { UsersProvider, UsersContext };
