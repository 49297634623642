import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

firebase.initializeApp(window.FIREBASE_CONFIG_JSON);

const reviewStorageName = window.location.hostname.split('.')[0] === 'admin' ? "storier-review-production" : "storier-review"

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const reviewStorage = firebase.app().storage(`gs://${reviewStorageName}`);
export const auth = firebase.auth();
export const fieldValue = firebase.firestore.FieldValue;
export const functions = firebase.functions();

export const DOC_IDS = firebase.firestore.FieldPath.documentId();
export const DELETE_FIELD = firebase.firestore.FieldValue.delete();

// functions.useFunctionsEmulator("http://localhost:5001");

export const signInWithEmail = async (email, password) => {
  try {
    let UserCredential = await auth.signInWithEmailAndPassword(email, password)
    let uid = UserCredential.user.uid;
    let userData = (await firestore.collection('users')
      .doc(uid)
      .get())
      .data();

    const isAdmin = userData.private && userData.private.isAdmin;

    console.log(isAdmin);

    if (!isAdmin) {
      console.log("Not an admin, logging out...");
      await signOut();
    }

  } catch (error) {
    console.log(error.code);
    console.log(error.message);
  }
};

export const signOut = async () => {
  await auth.signOut();
  window.location = "/";
};

export const getUserDocument = uid => {
  if (!uid) return null;
  try {
    return firestore.collection("users").doc(uid);
  } catch (error) {
    console.error("Error fetching user", error.message);
  }
};

export default firebase;
