/**
 * Gets ID along with Doc when querying Firestore
 * @param {Object} doc
 */
export const collectIdsAndDocs = doc => {
  return { id: doc.id, ...doc.data() };
};

/**
 * Replaces all accented chars with regular ones
 */
export const replaceAccents = str => {
  // Verifies if the String has accents and replace them
  if (str.search(/[\xC0-\xFF]/g) > -1) {
    str = str
      .replace(/[\xC0-\xC5]/g, "A")
      .replace(/[\xC6]/g, "AE")
      .replace(/[\xC7]/g, "C")
      .replace(/[\xC8-\xCB]/g, "E")
      .replace(/[\xCC-\xCF]/g, "I")
      .replace(/[\xD0]/g, "D")
      .replace(/[\xD1]/g, "N")
      .replace(/[\xD2-\xD6\xD8]/g, "O")
      .replace(/[\xD9-\xDC]/g, "U")
      .replace(/[\xDD]/g, "Y")
      .replace(/[\xDE]/g, "P")
      .replace(/[\xE0-\xE5]/g, "a")
      .replace(/[\xE6]/g, "ae")
      .replace(/[\xE7]/g, "c")
      .replace(/[\xE8-\xEB]/g, "e")
      .replace(/[\xEC-\xEF]/g, "i")
      .replace(/[\xF1]/g, "n")
      .replace(/[\xF2-\xF6\xF8]/g, "o")
      .replace(/[\xF9-\xFC]/g, "u")
      .replace(/[\xFE]/g, "p")
      .replace(/[\xFD\xFF]/g, "y");
  }

  return str;
};

/**
 * Make a date string
 * i.e. 2020-07-08
 */
export const createDateString = () => {
  let d = new Date();
  let dateStr = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

  return dateStr;
};

/**
 * Create a formatted ID that can be used in Firestore as well as an URL.
 * @param {string} title
 */
export const createCatalogId = title => {
  let id = replaceAccents(title)
    .replace(/[^A-Z0-9]+/gi, "-")
    .toLowerCase();
  if (id.charAt(id.length - 1) === "-") {
    id = id.substring(0, id.length - 1);
  }

  return id;
};

/**
 * From https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 * Make a n-number long unique ID.
 * @param {integer} length
 *
 * Use:
 * console.log(makeid(5))
 */
export const makeId = length => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const ROYALTY_SCHEMES = [
  { value: 'PAY_BY_LISTEN_TIME', label: 'Pay by Listen Time' },
  { value: 'PAY_BY_MIN_PERCENT_OR_MINUTES', label: 'Pay by Minimum of Percentage or Minutes' }
];

export const SERIES_COSTS = [
  { value: 'FIXED', label: 'Fixed' },
  { value: 'SERIES_BASED', label: 'Series Based' }
];

export const ASSIGNMENT_OPTIONS = [
  { value: "true", label: 'Yes' },
  { value: "false", label: 'No' }
];

export const AGE_GROUPS = [
  { label: 'Ages 0-1', value: 'ages-0-to-1' },
  { label: 'Ages 2-3', value: 'ages-2-to-3' },
  { label: 'Ages 4-6', value: 'ages-4-to-6' },
  { label: 'Ages 7-12', value: 'ages-7-to-12' }
]