import React, { useState, createContext, useEffect } from "react";
import { firestore } from "../firebase";
import { collectIdsAndDocs } from "../utilities";

const ReportsContext = createContext({
  reports: []
});

const ReportsProvider = props => {
  const [{ reports }, setReports] = useState({
    reports: []
  });

  useEffect( () => {
    const unsubscribe = firestore
      .collection("reports")
      .onSnapshot(docRefs => {
        const reports = docRefs.docs.map(collectIdsAndDocs);
        setReports({reports});
      });

    return () => unsubscribe();
  }, []);

  return (
    <ReportsContext.Provider value={{ reports }}>
      {props.children}
    </ReportsContext.Provider>
  );
};

export { ReportsProvider, ReportsContext };
