import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";

import withUser from "./withUser";
import { signInWithEmail } from "../firebase";
import backgroundImg from "../assets/img/bookscolor.jpg";
import logo from "../assets/img/brand/storier-logo.png";

class Login extends Component {
  state = { email: "", password: "" };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { email, password } = this.state;
    await signInWithEmail(email, password);
    this.setState({ email: "", password: "" });
  };

  render() {
    const { email, password } = this.state;
    const { user, location } = this.props;
    if (user) {
      const from = location.state ? location.state.from : "/";
      return <Redirect to={from} />;
    }

    return (
      <div
        className="app flex-row align-items-center"
        style={{
          background: `url(${backgroundImg}) center`,
          backgroundSize: "cover"
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <form onSubmit={this.handleSubmit}>
                      <div className="mb-5 text-center">
                        <img
                          src={logo}
                          alt="Storier Logo"
                          style={{ maxWidth: 200 }}
                        />
                      </div>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          autoComplete="email"
                          value={email}
                          onChange={this.handleChange}
                          name="email"
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={password}
                          onChange={this.handleChange}
                          name="password"
                        />
                      </InputGroup>
                      <Row>
                        <Col sm="12">
                          <Button
                            type="submit"
                            color="primary"
                            className="px-4 btn-block"
                          >
                            Login
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(withUser(Login));
