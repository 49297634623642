import React, { useState, createContext, useEffect } from "react";
import { firestore } from "../firebase";
import { collectIdsAndDocs } from "../utilities";

const SeriesContext = createContext({
  series: [],
  update: () => {}
});

async function getSeriesCosts() {
  return (await firestore
    .collection("seriesCosts")
    .get())
    .docs;
}

const SeriesProvider = props => {
  const [{ series }, setSeries] = useState({
    series: [],
    update: () => {}
  });
  
  const addSeriesPrivateData = async (serie) => {
    const docRefs = await firestore.collection('series').doc(serie.id).collection('private').get();
    let privateData = {};
    //privateData[docRefs.docs[0].id] = docRefs.docs[0].data();
    docRefs.docs.forEach(doc => {
      privateData[doc.id] = doc.data();
    });
    serie.private = privateData;
    return serie;
  }

  const updatePrivateAdmin = async (id) => {
    const serieIdx = series.findIndex((serie) => serie.id === id);
    series[serieIdx] = await addSeriesPrivateData(series[serieIdx]);
    setSeries({series});
  }
   
  useEffect( () => {
    const unsubscribe = firestore
      .collection("series")
      .onSnapshot(async docRefs => {
        const series = await Promise.all(docRefs.docs
          .map(collectIdsAndDocs)
          // .map(serie => {
          //   return addSeriesPrivateData(serie);
          // })
        );

        const seriesCosts = await getSeriesCosts()
        seriesCosts.forEach(doc => {
            let targetSeriesIdx = series.findIndex((serie => serie.id === doc.id))
            if (targetSeriesIdx !== -1)
            series[targetSeriesIdx].cost = doc.data()
        });

        setSeries({series});
      });
  
    return () => unsubscribe();
  }, []);

  return (
    <SeriesContext.Provider value={{ series:series, update:updatePrivateAdmin }}>
      {props.children}
    </SeriesContext.Provider>
  );
};

export { SeriesProvider, SeriesContext };
